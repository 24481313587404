.heading {
  display: inline-block;
  font-weight: bold;
  font-size: 52px;
  text-align: center;
  line-height: 56px;
}

.subheading {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--brand-dark-background);
  opacity: 0.9;
  padding: 0;
  margin: 0;
}

.container {
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  justify-content: center;
}

.pageSection {
  justify-content: center;
}

.buttonGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}

.linkButton {
  text-decoration: none;
  padding: 0 8px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 100%;
}

.linkButtonDark {
  background-color: var(--brand-dark-background);
  color: white;
}

.linkButtonDark:hover {
  color: white;
}

.linkButtonLight {
  background-color: transparent;
  color: var(--brand-dark-background);
  border: 2px solid var(--brand-dark-background);
}

.linkButtonLight:hover {
  color: var(--brand-dark-background);
}

.sectionWhatWeDo {
  padding: 96px 32px 48px;
}

@media (min-width: 600px) {
  .sectionWhatWeDo {
    padding: 96px 32px;
  }
}

.sectionWhatWeDoSubheading {
  font-weight: bold;
}

.sectionWithBackground {
  background-color: var(--brand-cool-background);
  padding: 64px 32px;
  border-radius: 10px;
}

.sectionWithBackgroundContainer {
  max-width: 660px;
  margin: 0 auto;
}

.sectionWhatWeDoTitle {
  font-size: 24px;
  font-weight: bold;
}

.sectionHowItWorksCardTitle {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}

@media (min-width: 600px) {
  .sectionWhatWeDoTitle {
    font-size: 32px;
  }

  .sectionHowItWorksCardTitle {
    font-weight: bold;
    font-size: 36px;
    text-align: left;
  }

  .buttonGroup {
    grid-template-columns: 1fr 1fr;
    max-width: 450px;
    margin: 0 auto;
  }
}

.teddyNumbers {
  padding: 96px 32px 48px;
}

.numbersGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media (min-width: 600px) {
  .teddyNumbers {
    padding: 96px 32px;
  }

  .numbersGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.numbersCard {
  background-color: #e7eff3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howItWorksSection {
  padding: 96px 32px 48px;
}

.sectionHowItWorksCardList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  justify-content: center;
}

.howItWorksCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.genericTeddy {
  width: 128px;
  height: 128px;
}

@media (min-width: 600px) {
  .genericTeddy {
    width: 300px;
    height: 300px;
  }

  .sectionHowItWorksCardList {
    grid-row-gap: 64px;
  }

  .howItWorksCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

.cardImageDots {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(-8px);
  z-index: -1;
}
