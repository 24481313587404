.modal {
  position: fixed;
  top: 15vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 12.5rem);
  width: 25rem;
}

.displayTeddyFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.displayTeddyFooter span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}

.teddyImg {
  border-radius: 16px;
}
.teddyWrapper {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .modal {
    width: 80% !important;
    left: 10% !important;
  }
}
