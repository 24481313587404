.wrapper {
  display: flex;
  flex: 1;
}

.container {
  display: flex;
}

.footerSignature {
  margin: auto auto 0 auto;
  font-family: "PT Root UI" !important;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 4rem;
  padding-top: 2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 992px) {
  .footerSignature a {
    font-size: 16px;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
