@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --wcm-z-index: 9999;
}

body > iframe {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

/* Load custom fonts */
@font-face {
  font-family: "Londrina Solid";
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Black.ttf);
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Regular.ttf);
}
@font-face {
  font-family: "PT Root UI";
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff2) format("woff2"),
    url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff) format("woff");
}
@font-face {
  font-family: "PT Root UI";
  font-weight: 500;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff2) format("woff2"),
    url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff) format("woff");
}

@font-face {
  font-family: "PT Root UI";
  font-weight: bold;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff2) format("woff2"),
    url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff) format("woff");
}
