.getGlmrButton {
  border: 2px solid;
  border-color: var(--brand-dark-background);
  background-color: unset;
  border-radius: 4px;
  font-weight: bold;
  padding: 4px 8px;
  transition: ease-in 0.1s;
}

.getGlmrButton:hover {
  background-color: var(--brand-dark-background);
  color: white;
  transition: ease-in 0.1s;
}
