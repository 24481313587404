.img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.imgWrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.circular {
  border-radius: 50%;
}

.delegateViewCircular {
  border-radius: 50%;
  margin-top: 13px;
}

.circleWithBorder {
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.rounded {
  border-radius: 15px;
}

.circularTeddyWrapper {
  width: 42px;
  height: 42px;
}

.delegateViewCircularTeddyWrapper {
  width: 42px;
  height: 42px;
  margin-left: 12px;
}

@media (max-width: 1200px) {
  .circularTeddyWrapper {
    height: 70%;
    width: 70%;
  }
}
